// 登录页面接口
import { request } from '@/utils/requset.js'
// import { request2 } from '../../utils/request2.js'


// 注册
const reject = (data)=>{
    return request({
        url:'/api/index/res',
        method:'post',
        data:data
    })
}
// 卡通督导下级账号
const add_user= (data)=>{
    return request({
        url:'/api/dudao/add_user',
        method:'post',
        data:data
    })
}
// 登录
const login = (data)=>{
    return request({
        url:'/api/login/index',
        method:'post',
        data:data
    })
}
// 获取权限下拉框
const getselect = (data)=>{
    return request({
        url:'/api/auth/adminList',
        method:'post',
        data:data
    })
}
// 获取管理员列表
const getadminlist = (data)=>{
    return request({
        url:'/api/index/user',
        method:'post',
        data:data
    })
}
// 编辑管理员列表
const editlist = (data)=>{
    return request({
        url:'/api/index/userEdit',
        method:'post',
        data:data
    })
}
// 人员管理重置密码
const respwd = (data)=>{
    return request({
        url:'/api/index/pwd',
        method:'post',
        data:data
    })
}
// 人员管理删除
const deleteperson = (data)=>{
    return request({
        url:'/api/index/resDel',
        method:'post',
        data:data
    })
}
export{
    reject,
    login,
    getselect,
    getadminlist,
    editlist,
    respwd,
    deleteperson,
    add_user
}



