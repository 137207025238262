<template>
  <div class="content">
    <el-button @click="addrole" class="search" icon="el-icon-plus" size="mini"
      >增加权限</el-button
    >

    <!-- 表格 -->
    <div class="tablebox">
      <el-table
        :data="tableData"
        row-key="id"
        size="mini"
        style="
          width: 93.5%;
          margin-top: 54px;
          margin-left: 20px;
          margin-bottom: 20px;
        "
        :tree-props="{ children: 'son', hasChildren: 'hasChildren' }"
      >
        >

        <el-table-column label="权限名称" width="auto">
          <template slot-scope="scope">
            <span @click="handle(scope.$index, scope.row)">
              <span>{{ scope.row.title }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="icon" width="auto">
          <template slot-scope="scope">
              <span>{{ scope.row.ico }}</span>
          </template>
        </el-table-column>
        <el-table-column label="router" width="auto">
          <template slot-scope="scope">
              <span :style="{ color: scope.row.color }">{{
                scope.row.name
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              style="color: #2a84e9; cursor: pointer"
              class="hover"
              @click="redact(scope.$index, scope.row)"
            >
              编辑
            </span>
            <span
              style="color: #bc1d22; cursor: pointer"
              class="hover"
              @click="handlete(scope.$index, scope.row)"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页dom -->
    <div class="block">
      <el-pagination
        :pager-count="pagercount"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pagesize"
        layout="total, prev, pager, next,sizes"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getrolelist, deleterole } from "@/Api/roal/index.js";
import message from "@/utils/messages";
export default {
  data() {
    return {
      pagercount: 5,
      tableData: [
        {
          id: 1,
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          id: 2,
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          id: 3,
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          asd: [
            {
              id: 31,
              date: "2016-05-01",
              name: "王小虎",
              address: "上海市普陀区金沙江路 1519 弄",
            },
            {
              id: 32,
              date: "2016-05-01",
              name: "王小虎",
              address: "上海市普陀区金沙江路 1519 弄",
              asd: [
                {
                  id: 99,
                  date: "2016-05-01",
                  name: "王小虎",
                  address: "上海市普陀区金沙江路 1519 弄",
                },
                {
                  id: 98,
                  date: "2016-05-01",
                  name: "王小虎",
                  address: "上海市普陀区金沙江路 1519 弄",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ], // 表格数据
      currentPage: 1, // 当前页数
      pagesize: 10, // 页数大小
      total: 10, // 数据总条数,
    };
  },
  methods: {
    // 编辑
    redact(index, row) {
      console.log(index, row);
      this.$router.push({
        path: "/rolechange",
        query: {
          title: row.title,
          status: row.status,
          indexname: row.name,
          id: row.id,
          pid: row.pid,
          ico: row.ico,
        },
      });
    },
    // 重置密码
    resetpwd() {},
    // 删除
    async handlete(index, row) {
      console.log(index);
      await deleterole({
        token: sessionStorage.getItem("token"),
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          this.getlist();
          message("删除成功", "success");
        }
      });
    },
    // 增加权限
    addrole() {
      this.$router.push("/addrole");
    },
    handleSizeChange() {},
    handleCurrentChange() {},
    search() {
      console.log(123);
    },
    handle(a, b) {
      console.log(a, b);
    },
    // 获取权限列表
    async getlist() {
      await getrolelist({
        token: sessionStorage.getItem("token"),
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
  },
  mounted() {
    this.getlist();
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 21rem;
  height: 12.4rem;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .search {
    margin-top: 20px;
    width: auto;
    height: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.2rem;
    background-color: #bc1d22;
    color: #fff;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
  }
}
::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 32px;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
::v-deep .el-pagination {
  margin-left: 14rem;
  padding-top: 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #bc1d22;
  color: #fff;
}
::v-deep .el-input__suffix {
  padding-top: 6px;
  right: 5px;
  transition: all 0.3s;
  pointer-events: none;
}
</style>
