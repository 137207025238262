// 权限相关接口
import { request } from '@/utils/requset.js'
// import { request2 } from '../../utils/request2.js'


// 获取权限列表
const getrolelist = (data)=>{
    return request({
        url:'/api/auth/index',
        method:'post',
        data:data
    })
}
// 添加角色列表
const roleadd = (data)=>{
    return request({
        url:'/api/auth/adminAdd',
        method:'post',
        data:data
    })
}
// 修改角色列表
const roleachange = (data)=>{
    return request({
        url:'/api/auth/adminEdit',
        method:'post',
        data:data
    })
}
// 获取角色列表
const rolelist = (data)=>{
    return request({
        url:'/api/auth/admin',
        method:'post',
        data:data
    })
}
// 权限新增
const addrole = (data)=>{
    return request({
        url:'/api/auth/authAdd',
        method:'post',
        data:data
    })
}
// 权限修改
const changerole = (data)=>{
    return request({
        url:'/api/auth/authEdit',
        method:'post',
        data:data
    })
}
// 权限删除
const deleterole = (data)=>{
    return request({
        url:'/api/auth/authDel',
        method:'post',
        data:data
    })
}
// 角色删除
const roledelet = (data)=>{
    return request({
        url:'/api/auth/adminDel',
        method:'post',
        data:data
    })
}
export{
    getrolelist,
    roleadd,
    rolelist,
    roleachange,
    addrole,
    changerole,
    deleterole,
    roledelet
}



