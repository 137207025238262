import {ceshi} from '@/utils/requset'
const config = {
    // 编辑器不自动被内容撑高
    UEDITOR_HOME_URL: '/UEditor/',
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 400,
    // 初始容器宽度
    initialFrameWidth: 800,
    serverUrl: ceshi + '/api/Ueditor',
    // serverUrl: 'https://falvmanage.59156.cn/admin/Ueditor',
    toolbars: [
      [
        'source',
        '|',
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'superscript',
        'subscript',
        'removeformat',
        'formatmatch',
        'pasteplain',
        '|',
        'forecolor',
        'backcolor',
        'insertorderedlist',
        'insertunorderedlist',
        'selectall',
        'cleardoc',
        '|',
        'rowspacingtop',
        'rowspacingbottom',
        'lineheight',
        '|',
        'customstyle',
        'paragraph',
        'fontfamily',
        'fontsize',
        '|',
        'justifyleft',
        'justifycenter',
        'justifyright',
        'justifyjustify',
        '|',
        'touppercase',
        'tolowercase',
        '|',
        'insertimage',
        'date',
        'time',
        'spechars',
        'searchreplace',
      ]
    ]
  }
  export default config
  