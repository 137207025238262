<template>
  <div class="content">
    <el-input
      style="width: 400px; margin-left: 24px; margin-top: 24px"
      placeholder="订单号/购买人ID/手机号/呢称"
      v-model="input1"
    >
      <i slot="suffix" class="el-icon-search" @click="search"></i>
    </el-input>
    <el-button @click="addperson" class="search" icon="el-icon-plus" size="mini"
      >人员管理</el-button
    >
    <!-- 表格 -->
    <div class="tablebox">
      <el-table
        :data="tableData"
        size="mini"
        style="width: 93.5%; margin-top: 54px; margin-left: 20px"
      >
        <el-table-column label="姓名" width="auto">
          <template slot-scope="scope">
            <div @click="redact(scope.$index, scope.row)">
              <span>{{ scope.row.username }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="手机号" width="auto">
          <template slot-scope="scope">
            <div @click="redact(scope.$index, scope.row)">
              <span>{{ scope.row.phone }}</span>
            </div>
          </template>
        </el-table-column>
                <el-table-column label="角色" width="auto">
          <template slot-scope="scope">
            <div @click="redact(scope.$index, scope.row)">
              <span :style="{ color: scope.row.color }">{{
                scope.row.group_title
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="身份" width="auto">
          <template slot-scope="scope">
            <div @click="redact(scope.$index, scope.row)">
              <span :style="{ color: scope.row.color }">{{
                scope.row.status
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="所属公司" width="auto">
          <template slot-scope="scope">
            <div @click="redact(scope.$index, scope.row)">
              <span :style="{ color: scope.row.color }">{{
                scope.row.company_id==2?'法务二司':'云律素材号'
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="auto">
          <template slot-scope="scope">
            <div @click="redact(scope.$index, scope.row)">
              <span :style="{ color: scope.row.color }">{{
                scope.row.createtime
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              style="color: #2a84e9; cursor: pointer"
              class="hover"
              @click="redact(scope.$index, scope.row)"
            >
              编辑
            </span>
            <span
              style="color: #2a84e9; cursor: pointer"
              class="hover"
              @click="resetpwd(scope.$index, scope.row)"
            >
              重置密码
            </span>
            <span
             v-if="scope.row.username !== 'admin'"
              style="color: #bc1d22; cursor: pointer"
              class="hover"
              @click="handlete(scope.$index, scope.row)"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页dom -->
    <div class="block">
      <el-pagination
        :pager-count="pagercount"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pagesize"
        layout="total, prev, pager, next,sizes"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      v-model="direction"
      title="重置密码"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <span style="margin-left:20px;margin-right:20px;color:#a2a5a8;">请输入重置密码</span> 
      <el-input
      style="width:300px;"
      show-password
  placeholder="请输入重置密码"
  v-model="resetpwds"
>
</el-input>
<div style="margin-top:20px">
   <span style="margin-left:20px;margin-right:20px;color:#a2a5a8;">请再次重置密码</span> 
      <el-input
      show-password
      style="width:300px;"
  placeholder="确认重置密码"
  v-model="resetpwds2"
>
</el-input>
</div>
<!-- 按钮 -->
    <div class="btnbox">
      <el-button
        type="info"
        style="background: #f2f3f5; border: 0; color: #666666"
        size="mini"
        @click="reback"
        >返回</el-button
      >
      <el-button
        type="info"
        size="mini"
        style="background-color: #bc1d22; border: 0"
        @click="save"
        >保存</el-button
      >
    </div>
    </el-drawer>
  </div>
</template>

<script>
import { getadminlist,respwd,deleteperson } from "@/Api/login/index.js";
import { MessageBox } from 'element-ui';
import message from '@/utils/messages';
export default {
  data() {
    return {
      id:'',// 修改密码id
      resetpwds:'',// 重置密码
      resetpwds2:'',// 再次重置密码
       drawer: false,
        direction: 'rtl',
      pagercount: 5,
      input1: "", // 搜索框内容
      tableData: [], // 表格数据
      currentPage: 1, // 当前页数
      pagesize: 10, // 页数大小
      total: 10, // 数据总条数
    };
  },
  methods: {
    // 编辑
    redact(index, row) {

   
      this.$router.push({
        path: "/personechange",
        query: {
          nickname:row.nickname,
          username: row.username,
          phone:row.phone,
          status:row.status == '督导'? '1':'2',
          buy_status:row.buy_status,
          id:row.id,
          group_id:row.group_id,
          company_id:row.company_id
        },
      });
    },
    // 返回
    reback(){
      this.drawer = false
    },
   async save(){
     if(this.resetpwds !== this.resetpwds2){
       message('两次密码不一致','error')
       return
     }
     await respwd({
       token:sessionStorage.getItem('token'),
       id:this.id,
       password:this.resetpwds
     }).then(res=>{
       if(res.code == 1){
         message('修改成功','success')
         this.drawer = false
       }
     })
    },
    // 抽屉
    handleClose(){

      MessageBox.confirm(
        '确认关闭？', '提示', {
  confirmButtonText: '确定',
  cancelButtonText: '取消',
  type: 'warning'
}).then(() => {
  this.drawer = false
  // 点击确定按钮后的回调
}).catch(() => {
  // 点击取消按钮后的回调
})
      
    },
    // 重置密码
    resetpwd(index,row) {
       this.drawer = true
       this.id = row.id
    },
    // 删除
   async handlete(index,row) {
     await deleteperson({
       token:sessionStorage.getItem('token'),
       id:row.id
     }).then(res=>{
       if(res.code == 1){
         message('删除成功','success')
         this.getlist()
       }
     })
   },
    // 添加人员
    addperson() {
      this.$router.push("/personedit");
    },
    handleSizeChange(e) {
      this.pagesize = e;
      this.getlist();
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.getlist();
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.$router.push("/carmidetail");
    },
    async getlist() {
      console.log(sessionStorage.getItem("token"));
      await getadminlist({
        token: sessionStorage.getItem("token"),
        page: this.currentPage,
        pageSize: this.pagesize,
      }).then((res) => {
        if (res.code == 1) {
          res.data.list.forEach((item) => {
            if (item.status == 1) {
              item.status = "督导";
            }
            if (item.status == 2) {
              item.status = "非督导";
            }
          });
          this.tableData = res.data.list;
          this.total = res.data.count;
        }
      });
    },
    search() {
      console.log(123);
    },
  },
  async mounted() {
    this.getlist();
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 21rem;
  height: 12.4rem;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .search {
    width: auto;
    height: 0.4rem;
    margin-left: 0.8rem;
    margin-right: 0.2rem;
    background-color: #bc1d22;
    color: #fff;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
  }
  .btnbox{
    margin-left: 20px;
    margin-top: 40px;
  }
}
::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 32px;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
::v-deep .el-pagination {
  margin-left: 14rem;
  padding-top: 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #bc1d22;
  color: #fff;
}
::v-deep .el-input__suffix {
  padding-top: 6px;
  right: 5px;
  transition: all 0.3s;
  pointer-events: none;
}
</style>
