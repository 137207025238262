<template>
  <div class="content">
    <div class="titlebox">
      <div class="title"></div>
      <span class="title2">{{ titlename }}</span>
    </div>
    <!--  角色名称-->
    <div class="rolename">
      <span style="margin-right: 8px">角色名称</span>
      <el-input
        style="width: 375px"
        v-model="rolename"
        placeholder="请输入角色名称"
      ></el-input>
    </div>
    <!-- 角色描述 -->
    <div class="roledescribe">
      <span style="margin-right: 8px">角色描述</span>
      <el-input
        style="width: 375px"
        type="textarea"
        autosize
        placeholder="请输入内容"
        v-model="describe"
      >
      </el-input>
    </div>
    <div class="treebox">
      <span>权限设置</span>
      <el-tree
        ref="tree"
        class="tree"
        :data="datas"
        show-checkbox
        :props="props"
        node-key="id"
        default-expand-all
        check-strictly
        @check="check"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>{{ data.title }}</span>
        </span>
      </el-tree>
    </div>

    <!-- 按钮 -->
    <div class="btnbox">
      <el-button
        type="info"
        style="background: #f2f3f5; border: 0; color: #666666"
        size="mini"
        @click="reback"
        >返回</el-button
      >
      <el-button
        type="info"
        size="mini"
        style="background-color: #bc1d22; border: 0"
        @click="save"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { getrolelist, roleadd,roleachange } from "@/Api/roal/index.js";
import message from '@/utils/messages';
// (roleadd);
export default {
  data() {
    return {
      titlename: "新增",
      roules: "", // 用户组权限id
      rouleid:'',// 用户组id
      describe: "", // 角色描述
      rolename: "", // 角色名称
      props: { children: "son", label: "title" },
      datas: [
        {
          id: 2,
          label: "概要",
          index: "/summary",
          icon: "el-icon-s-home",
        },
        {
          id: 3,
          label: "管理中心",
          index: "管理中心",
          icon: "el-icon-user-solid",
          name: "管理中心",
          asd: [
            {
              id: 4,
              label: "邀请列表",
              index: "/invitelist",
              name: "邀请列表",
              children: [
                {
                  id: 9,
                  label: "look_list",
                },
              ],
            },
            {
              id: 5,
              label: "卡密管理",
              index: "/carmimanage",
              name: "卡密管理",
              asd: [
                {
                  id: 11,
                  label: "look_code",
                },
              ],
            },
          ],
        },
        {
          id: 6,
          label: "系统管理",
          index: "系统管理",
          icon: "el-icon-s-tools",
          name: "系统管理",
          asd: [
            {
              id: 7,
              label: "人员管理",
              index: "/personmangent",
              name: "人员管理",
            },
            {
              id: 8,
              label: "角色管理",
              index: "/rolemangent",
              name: "角色管理",
            },
            {
              id: 10,
              label: "购买管理",
              index: "/buymangent",
              name: "购买管理",
            },
          ],
        },
      ],
    };
  },
  methods: {
    check(e, a, b) {
      console.log(a, b,e);
      let roules = "";
      if (a.checkedNodes.length > 0) {
     
        a.checkedNodes.forEach((item) => {
          roules += item.id + ",";
        });
           if(a.halfCheckedKeys.length > 0){
          roules = a.halfCheckedKeys[0] + ',' +  roules
        }
      }
      
      this.roules = roules;
      if(this.titlename == '修改'){
        let roules = '' // 用户组权限id
         if (a.checkedNodes.length > 0) {
        a.checkedNodes.forEach((item) => {
          roules += item.id + ',';
        });
         
      }
            if(a.halfCheckedKeys.length > 0){
          roules = a.halfCheckedKeys[0] + ',' +  roules
        }
         this.roules = roules;
      }
    },
    reback() {
      this.$router.push("/rolemangent");
    },
    async save() {
      if(this.titlename == '新增'){
      await roleadd({
        token: sessionStorage.getItem("token"),
        title: this.rolename,
        status: 1,
        commit: this.describe,
        rules: this.roules,
      }).then((res) => {
           if(res.code == 1){
             message('保存成功','success')
              this.$router.push('/rolemangent')
          }
      });
      }
      if(this.titlename == '修改'){
        await roleachange({
           token: sessionStorage.getItem("token"),
          id:this.rouleid,
          title:this.rolename,
          status:1,
          rules:this.roules,
          commit:this.describe
        }).then(res=>{
          if(res.code == 1){
   message('修改成功','success')
   this.$router.push('/rolemangent')
          }
        })
      }

    },
    // 默认选中节点
    setCheckedKeys(arr) {
      this.$refs.tree.setCheckedKeys(arr);
    },
  },
  async mounted() {
    // 如果是列表页跳转进入
    if (this.$route.query.rules) {
      let arr = this.$route.query.rules.split(",");
      arr.splice(-1);
        console.log(arr);
      this.setCheckedKeys(arr);
      this.titlename = "修改";
    }
    // 如果是列表页跳转进入
    if (this.$route.query.title) {
      this.rolename = this.$route.query.title;
        let arr = this.$route.query.rules.split(",");
      arr.splice(-1);
        console.log(arr);
        this.setCheckedKeys(arr);
      this.titlename = "修改";
    }
    // 如果是列表页跳转进入
    if (this.$route.query.commit) {
      this.describe = this.$route.query.commit;
        let arr = this.$route.query.rules.split(",");
          console.log(arr);
      arr.splice(-1);
        this.setCheckedKeys(arr);
      this.titlename = "修改";
    }
        if (this.$route.query.id) {
      this.rouleid = this.$route.query.id;
        let arr = this.$route.query.rules.split(",");
        console.log(arr);
      arr.splice(-1);
        this.setCheckedKeys(arr);
      this.titlename = "修改";
    }
    await getrolelist({
      token: sessionStorage.getItem("token"),
    }).then((res) => {
      if (res.code == 1) {
        console.log(res.data);
        this.datas = res.data;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 21rem;
  height: 12.4rem;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 20px;
  .rolename {
    display: flex;
    align-items: center;
    margin-left: 0.9867rem;
    margin-top: 0.7333rem;
  }
  .roledescribe {
    display: flex;
    align-items: center;
    height: 52px;
    margin-left: 0.9867rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .treebox {
    display: flex;
    margin-left: 0.9867rem;
  }
  .titlebox {
    display: flex;
    align-items: center;
    margin-left: 0.3rem;
    padding-top: 0.3rem;
    .title {
      width: 3px;
      height: 18px;
      background: #bc1d22;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-right: 8px;
    }
    .title2 {
      width: 80px;
      height: 24px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
  }

  .btnbox {
    margin-left: 80px;
    margin-top: 30px;
  }
}
::v-deep .el-textarea__inner {
  height: 53px !important;
}
::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 32px;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
</style>
