import axios from 'axios';
import message from "@/utils/messages.js";
import router from '../router'
import { Loading } from "element-ui";
 //定义变量
 let loading;
 //定义开始加载函数
function startLoading() {
 //创建loading实例
   loading = Loading.service({
       lock: true,
       text: "拼命加载中...",
       background: 'rgba(0,0,0,0.8)'
   })
}
//定义关闭加载函数
function endLoading() {
   loading.close()
}
// let ceshi = 'http://dudaoapi.fmyunlv.com/api'
let ceshi = 'https://api.zkyunlv.com' 
// 创建一个 Axios 实例
const service = axios.create({
  baseURL: ceshi, // 接口的基础路径
headers:{
    "Content-type": 'application/x-www-form-urlencoded',
},
  // timeout: 5000, // 请求超时时间
});
// 请求拦截器
service.interceptors.request.use(
  (config) =>{
    startLoading()
    const token = sessionStorage.getItem('token')
    if(token){
      config.headers['token'] = token
    }
      return config;
    } ,
  (error) => {
    // 处理请求错误

    console.error(error);
    return Promise.reject(error);
  });
// 响应拦截器
service.interceptors.response.use(
  (response) =>{
    endLoading()
    if(response.data.code == 400300){
      message('无权限访问','error')
        router.push('/')
    }
    if(response.code == 400300){
      message('无权限访问','error')
      router.push('/')
  }
    if(response.data.code == 400400){
      message('token过期重新登录','error')
      sessionStorage.clear()
      router.push('/login')
  }
  if(response.code == 400400){
    message('token过期重新登录','error')
    sessionStorage.clear()
    router.push('/login')
}
    return response.data
  } ,
  (error) => {
    // 处理响应错误
    message('请求数据失败','error')
    endLoading()
    console.log(error);
  });
  export const request = service;
  export { ceshi };