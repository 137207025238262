import Vue from 'vue'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'   // 首页
import TypeManagent from '../views/home/summary/TypeManagent.vue'   // 素材分类管理
import LonginView from '../views/login/LoginView.vue' // 登录页
import SumMary from '../views/home/summary/SumMary.vue' // 素材设置页
import SwiperManagent from '../views/home/summary/SwiperManagent' // swiper管理
import SucaiCount from '../views/home/summary/SucaiCount' // 素材统计
import RankCount from '../views/home/summary/RankCount' // 排行统计
// import InviteList from '../views/home/managementcenter/InviteList.vue' // 管理中心/邀请列表页
import PersonMangent from '../views/home/SystemManagement/PersonManagement.vue' // 系统管理/人员管理页
import PersonEdit from '../views/home/SystemManagement/PersonEdit.vue' // 系统管理/人员管新增页
import PersonCchange from '../views/home/SystemManagement/PersonChange.vue' // 系统管理/人员管理修改页
import RoleMangent from '../views/home/SystemManagement/RoleMangent.vue' // 系统管理/角色管理页
import RoleEdit from '../views/home/SystemManagement/RoleEdit.vue' // 系统管理/角色管理编辑页
import RoleList from '../views/home/SystemManagement/RoleList.vue' // 系统管理/权限列表
import AddRole from '../views/home/SystemManagement/AddRole.vue' // 系统管理/增加权限
import RoleChange from '../views/home/SystemManagement/RoleChange.vue' // 系统管理/修改权限
import UserManagent from '../views/home/SystemManagement/UserManagent.vue' // 系统管理/用户管理
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
  path: '/',name: 'home',
  redirect:'/summary',
  component: HomeView,
  children:[
    {
      path:'summary',
      name:'素材设置',
      component:SumMary
    },
    {
      path:'typemanagent',
      name:'素材分类管理',
      component:TypeManagent
    },
    {
      path:'swipermanagent',
      name:'首页轮播',
      component:SwiperManagent
    },
    {
      path:'sucaicount',
      name:'素材统计',
      component:SucaiCount
    },
    {
      path:'rankcount',
      name:'排行统计',
      component:RankCount
      
    },
    {
      path:'personmangent',
      name:'人员管理',
      component:PersonMangent
    },
    {
      path:'personedit',
      name:'人员管理新增',
      component:PersonEdit
    },
    {
      path:'personechange',
      name:'人员管理修改',
      component:PersonCchange
    },
    {
      path:'rolemangent',
      name:'角色管理',
      component:RoleMangent
    },
    {
      path:'roleedit',
      name:'角色管理编辑',
      component:RoleEdit
    },
  
    {
      path:'rolelist',
      name:'权限列表',
      component:RoleList
    },
    {
      path:'usermanagent',
      name:'用户管理',
      component:UserManagent
    },
    {
      path:'addrole',
      name:'增加权限',
      component:AddRole
    },
    {
      path:'rolechange',
      name:'修改权限',
      component:RoleChange
    }
  
  ]
  },
  {path: '/login',name: 'login',component: LonginView},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next)=>{
  const token = sessionStorage.getItem('token')
  if (token && to.name == "login") next({ name: "home" });
  if (!token && to.name !== "login") next({ name: "login" });

  NProgress.start();
  store.commit("home/changebreadlsit", [localStorage.getItem('bread'),to.name]);
  // if(to.name == '素材设置'){
  //   store.commit("home/changebreadlsit", []);
  // }
  next()
})
// 后置守卫
router.afterEach(() => {
  NProgress.done();
});

export default router
