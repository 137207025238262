import {
    Button,
    Input,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Avatar,
    Breadcrumb,
    BreadcrumbItem,
    Aside,
    DatePicker,
    Tabs,
    TabPane,
    Select,
    Option,
    Table,
    TableColumn,
    Pagination,
    Radio,
    Checkbox,
    CheckboxGroup,
    Drawer,
    Icon,
    Tree,
    Switch,
    Cascader,
    Dialog,
    Row,
    Upload,
    FormItem,
    Form,
    RadioGroup,
    InputNumber,
    Transfer
  } from 'element-ui'
  
  const coms = [
    Button, 
    Input, 
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Avatar,
    Breadcrumb,
    BreadcrumbItem,
    Aside,
    DatePicker,
    Tabs,
    TabPane,
    Select,
    Option,
    Table,
    TableColumn,
    Pagination,
    Radio,
    Checkbox,
    CheckboxGroup,
    Drawer,
    Icon,
    Tree,
    Switch,
    Cascader,
    Dialog,
    Row,
    Upload,
    FormItem,
    Form,
    RadioGroup,
    InputNumber,
    Transfer
  ]
  
  export default {
    install(Vue){
      coms.map(c => {
        Vue.component(c.name, c)
      })
    }
  }