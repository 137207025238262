<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingTitle"
    class="app-container"
  >
    <div class="white-container clearfix">
      <span style="font-size: 14px">一级分类选择：</span>
      <el-select
        @change="changeOne"
        size="small"
        clearable
        style="margin-top: 10px"
        v-model="oneTypeValue"
        placeholder="选择一级分类"
      >
        <el-option
          v-for="item in oneTypeList"
          :key="item.id"
          :label="item.type_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span style="font-size: 14px; margin-left: 10px">二级分类选择：</span>
      <el-select
        @change="changeTwo"
        size="small"
        clearable
        style="margin-top: 10px"
        v-model="twoTypeValue"
        placeholder="选择二级分类"
      >
        <el-option
          v-for="item in twoTypeList"
          :key="item.id"
          :label="item.type_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span class="time" style="margin-left: 10px">日期选择：</span>
      <el-date-picker
        size="small"
        class="pictime"
        v-model="datevalue"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <el-button
        size="small"
        style="margin-left: 20px; margin-top: 20px"
        @click="search"
        icon="el-icon-search"
        type="primary"
      >
        搜索
      </el-button>
      <div class="neirong">
        <div class="left">
          <div style="width: 800px; margin-right: 20px">
            <h2 style="margin-bottom:10px;">下载前十排行</h2>
            <el-table :data="tableData.download" border style="width: 100%">
              <el-table-column prop="num" label="下载次数" width="auto">
              </el-table-column>
              <el-table-column prop="title" label="素材标题" width="auto">
              </el-table-column>
              <el-table-column
                prop="type_one"
                label="所属一级分类"
                width="auto"
              >
              </el-table-column>
              <el-table-column
                prop="type_two"
                label="所属二级分类"
                width="auto"
              >
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 800px">
            <h2 style="margin-bottom:10px;">分享前十排行</h2>
            <el-table :data="tableData.share" border style="width: 100%">
              <el-table-column prop="num" label="分享次数" width="auto">
              </el-table-column>
              <el-table-column prop="title" label="素材标题" width="auto">
              </el-table-column>
              <el-table-column
                prop="type_one"
                label="所属一级分类"
                width="auto"
              >
              </el-table-column>
              <el-table-column
                prop="type_two"
                label="所属二级分类"
                width="auto"
              >
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 800px; margin-right: 20px">
            <h2 style="margin-bottom:10px;margin-top:10px;">点赞前十排行</h2>
            <el-table :data="tableData.like" border style="width: 100%">
              <el-table-column prop="num" label="点赞次数" width="auto">
              </el-table-column>
              <el-table-column prop="title" label="素材标题" width="auto">
              </el-table-column>
              <el-table-column
                prop="type_one"
                label="所属一级分类"
                width="auto"
              >
              </el-table-column>
              <el-table-column
                prop="type_two"
                label="所属二级分类"
                width="auto"
              >
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 800px;">
            <h2 style="margin-bottom:10px;margin-top:10px;">浏览前十排行</h2>
            <el-table :data="tableData.view" border style="width: 100%">
              <el-table-column prop="num" label="浏览次数" width="auto">
              </el-table-column>
              <el-table-column prop="title" label="素材标题" width="auto">
              </el-table-column>
              <el-table-column
                prop="type_one"
                label="所属一级分类"
                width="auto"
              >
              </el-table-column>
              <el-table-column
                prop="type_two"
                label="所属二级分类"
                width="auto"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { leveltype,tenRank } from "@/Api/operation";
// import { Message } from "element-ui";
export default {
  data() {
    return {
      tableData:{},
      oneTypeValue: "", // 1级分类
      twoTypeValue: "", // 2级分类
      loadingTitle: "加载中...",
      loading: false,
      oneTypeList: [],
      twoTypeList: [],
      datevalue: "", // 日期值
      //日期初始化
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    changeOne(e) {
      this.oneTypeValue = e;
      // 获取二级分类
      this.getTwoType();
    },
    changeTwo(e) {
      this.twoTypeValue = e;
    },
    search() {
      this.gettenRank()
    },
    // 获取一级分类
    getOneType() {
      leveltype({
        type_id: "",
      }).then((res) => {
        if (res.code == 1) {
          this.oneTypeList = res.data;
        }
      });
    },
    // 获取二级分类
    getTwoType() {
      leveltype({
        type_id: this.oneTypeValue,
      }).then((res) => {
        if (res.code == 1) {
          this.twoTypeList = res.data;
        }
      });
    },
    // 默认排行数据
    gettenRank(){
      this.loading = true
      let param =  ''
         if(this.datevalue.length > 0){
        param = this.datevalue.join()
      }
      tenRank({
        type_id:this.twoTypeValue?this.twoTypeValue:this.oneTypeValue,
        date:param
      }).then(res=>{
         this.loading = false
        if(res.code == 1){
          this.tableData = res.data
        }
      }).catch(()=>{
            this.loading = false
      })
    }
  },
  created() {
    this.getOneType();
    this.gettenRank();
  },
};
</script>
<style lang="scss" scoped >
.app-container {
  .white-container {
    height: 100vh;
    overflow: auto;
    .add {
      margin-top: 20px;
    }
    .neirong {
      min-width: 600px;
      min-height: 600px;
      margin-top: 20px;
      display: flex;
      .left {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
