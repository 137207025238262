<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingTitle"
    class="app-container"
  >
    <div class="white-container clearfix">
      <div class="neirong">
        <div class="left">
          <el-table :data="tableData" style="width: auto">
            <el-table-column prop="mobile" label="手机号" width="auto">
            </el-table-column>
            <el-table-column prop="nickname" label="用户昵称" width="auto">
            </el-table-column>
            <el-table-column prop="is_nav" label="禁用状态" width="auto">
              <template slot-scope="scope">
                <el-switch
                  @change="changeSwich(scope.row)"
                  :active-value="1"
                  :inactive-value="0"
                  v-model="scope.row.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper">
            <el-pagination
              :current-page="page"
              :page-sizes="[10, 20]"
              :page-size="limit"
              background
              layout="total, sizes, prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { userList,setstatus } from "@/Api/operation";
import { Message } from "element-ui";
// import { MessageBox } from "element-ui";
export default {
  data() {
    return {
      value: 0,
      loadingTitle: "加载中...",
      loading: false,
      page: 1,
      limit: 10,
      total: 0,
      tableData: [],

    };
  },
  methods: {
    // 获取用户列表
    getList() {
      let that = this;
      userList({
       page:this.page,
       limit:this.limit
      }).then((res) => {
            this.loading = false;
        if (res.code == 1) {
          that.tableData = res.data.rows;
          this.total = res.data.total
        }
      }).catch(()=>{
          this.loading = false;
      })
    },
   changeSwich(row){
     setstatus({
       id:row.id,
       status:row.status
     }).then(res=>{
       if(res.code == 1){
          Message({
            message:'操作成功',
            type:'success'
          })
          this.getList()
       }
     })
   },
    // 改变页码
    handleCurrentChange(val) {
      this.loading = true;
      this.page = val;
      this.getList();
    },
    // 改变一页的数量
    handleSizeChange(val) {
      this.loading = true;
      this.page = 1;
      this.limit = val;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" >
.app-container {
  .white-container {
    height: 100vh;
    overflow: auto;
    .add {
      margin-top: 20px;
    }
    .neirong {
      min-width: 600px;
      min-height: 600px;
      margin-top: 20px;
      display: flex;
      .left {
        width: 100%;
      }
    }
  }
}
</style>
