import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import '@/icons' // icon
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify)
//引入 element 插件
import element from './components/element'

import { Loading } from 'element-ui';
Vue.use(Loading.directive);

Vue.use(element)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')





