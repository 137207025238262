<template>
  <div class="content">
    <div class="titlebox">
      <div class="title"></div>
      <span class="title2">增加权限</span>
    </div>
    <!-- 权限名称 -->
    <div>
      <span style="margin-left:36px">权限名称</span>
      <el-input  style="width: 400px; margin-left: 24px; margin-top: 24px" placeholder="请输入内容" v-model="rolename" clearable>
      </el-input>
    </div>
    <!-- 上级权限 -->
    <div >
      <span style="margin-left:36px">上级权限</span>
      <el-cascader
       style="width: 400px; margin-left: 24px; margin-top: 24px"
        v-model="selectedValues"
        :options="options"
        :props="cascaderProps"
        @change="handleChange"
        clearable
      ></el-cascader>
    </div>
    <!-- icon -->
      <div>
      <span style="margin-left:36px">icon</span>
      <el-input  style="width: 400px; margin-left: 48px; margin-top: 24px" placeholder="请输入内容" v-model="iconvalue" clearable>
      </el-input>
    </div>
    <!-- 路由地址 -->
    <div>
      <span style="margin-left:36px">路由地址</span>
      <el-input  style="width: 400px; margin-left: 24px; margin-top: 24px" placeholder="请输入内容" v-model="indexname" clearable>
      </el-input>
    </div>
    <!-- 禁用与否 -->
    <div style="margin-top: 24px">
      <span style="margin-left:36px">是否禁用</span>
      <el-radio style="margin-left: 24px;" v-model="radio" label="2">是</el-radio>
      <el-radio v-model="radio" label="1">否</el-radio>
    </div>
    <!-- 按钮组 -->
    <div style="margin-left:36px;margin-top: 48px">
        <el-button
        type="info"
        style="background: #f2f3f5; border: 0; color: #666666"
        size="mini"
        @click="reback"
        >返回</el-button
      >
      <el-button
        type="info"
        size="mini"
        style="background-color: #bc1d22; border: 0"
        @click="save"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { getrolelist,addrole } from "@/Api/roal/index.js";
import message from '@/utils/messages';
export default {
  data() {
    return {
      iconvalue:'', // icon
      rolename: "", // 权限名称
      indexname: "", // 路由地址
      radio:"1", // 是否禁用
      selectedValues: [], // 用于存储选择的值
      // 级联选择器的选项数据
      options: [
        {
          value1: "1",
          label1: "Level 1",
          asd: [
            {
              value1: "1-1",
              label1: "Level 1-1",
              asd: [
                {
                  value1: "1-1-1",
                  label1: "Level 1-1-1",
                },
                {
                  value1: "1-1-2",
                  label1: "Level 1-1-2",
                },
              ],
            },
            {
              value1: "1-2",
              label1: "Level 1-2",
            },
          ],
        },
        {
          value1: "2",
          label1: "Level 2",
        },
      ],
      // 配置级联选择器的属性名称
      cascaderProps: {
        value: "id",
        label: "title",
        children: "son",
        multiple: true,
        checkStrictly: true,
      },
    };
  },
  methods: {
    handleChange() {
    },
    reback(){
        this.$router.push('/rolelist')
    },
  async save(){
      await addrole({
         token:sessionStorage.getItem('token'),
         pid:this.selectedValues[0][this.selectedValues[0].length - 1],
         ico:this.iconvalue,
         name:this.indexname,
         title:this.rolename,
         type:1,
         status:this.radio,
         sort:0
      }).then(res=>{
          if(res.code == 1){
            message('增加成功','success')
            this.$router.push('/rolelist')
          }
      })
    }
  },
  async mounted() {
    await getrolelist({
      token: sessionStorage.getItem("token"),
    }).then((res) => {
      if (res.code == 1) {
          res.data.unshift({
              pid:0,
              title:'顶级权限'
          })
        this.options = res.data;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 21rem;
  height: 12.4rem;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .titlebox {
    display: flex;
    align-items: center;
    margin-left: 0.3rem;
    padding-top: 0.3rem;
    .title {
      width: 3px;
      height: 18px;
      background: #bc1d22;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-right: 8px;
    }
    .title2 {
      width: 80px;
      height: 24px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
  }

}
::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 32px;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
::v-deep .el-cascader{
   height: 32px;
  line-height: 32px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #c7161f;
    background: #c7161f;
}
::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #000000;
}
</style>
