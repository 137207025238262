import Vue from 'vue'
import Vuex from 'vuex'
import home from '@/store/home/index.js'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    home
  }
})
