<template>
  <div class="login-container">
        
    <star-background />
 <div class="loginbox">
     <div class="title">云律系统集成</div>
      <div>
        <el-input
          class="ip1"
          placeholder="请输入账号"
          prefix-icon="el-icon-user-solid"
          v-model="input2"
          clearable
        >
        </el-input>
      </div>
      <div>
        <el-input
          @keyup.enter.native="login"
          class="ip2"
          placeholder="请输入密码"
          prefix-icon="el-icon-lock"
          v-model="input"
          show-password
          clearable
        >
        </el-input>
      </div>

      <!-- 验证码 -->
          <div class="code" @click="generateCode">
         
            <div class="coder">
                 {{code}}
            </div>
            </div>
             <el-input
          class="ip2"
          placeholder="请输入验证码"
          v-model="yanzhengcode"
          clearable
        >
        </el-input>
      <el-button class="btn" type="primary" @click="login">登录</el-button>
    </div>
  </div>
</template>

<script>
import StarBackground from '@/components/StarBackground'
import { login } from "@/Api/login/index.js";
import message from "../../utils/messages.js";
export default {
  beforeCreate: function() {
    document.getElementsByTagName('body')[0].className = 'body-bg'
  },
  components: { StarBackground },
  data() {
    return {
      code:'Zy96',
      yanzhengcode:'',
      src: "",
      input2: "", // 账号
      input: "", // 密码
      drawer: false, // 是否开启抽屉
      text: "向右拖动滑块填充拼图", //滑块底部文字
      // state: "", //验证状态 1成功 2失败
    };
  },
  methods: {
     generateCode() {
       this.code = ''
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 4; i++) {
        var randomIndex = Math.floor(Math.random() * characters.length);
        this.code += characters.charAt(randomIndex);
      }
    },
    async login() {
      
      if (this.input2 == "") {
        message("账号不能为空", "error");
        return;
      } else if (this.input == "") {
        message("密码不能为空", "error");
        return;
      } 
      else if (this.code.toLocaleLowerCase() !== this.yanzhengcode.toLocaleLowerCase()) {
        message("验证码输入错误",'error');
        return;
      }
       else {
          await login({
        username: this.input2,
        password: this.input,
      })
        .then((res) => {
          this.generateCode()
          if(res.code == 1){
            message('登录成功','success');
            this.$store.commit("home/changetoken", res.data.token);
            this.$store.commit("home/changeusername", res.data.nickname);
            this.$store.commit("home/changestate", '/summary');
            sessionStorage.setItem('routerlist',JSON.stringify(res.data.rule_data) )
            sessionStorage.setItem('username',res.data.username)
            this.$router.push('/summary')
          }
          if(res.msg == "密码输入有误"){
            message('密码输入有误','error');
          }
             if(res.msg == "用户不存在"){
            message('账号不存在','error');
          }
        })
        .catch(() => {
          this.generateCode()
          message("登录失败", "error");
        });
      }
    
    },
    // 验证成功
    onSuccess() {
      message("验证成功", "success");
      this.state = 1;
    },
    // 验证是吧
    onFail() {
      message("验证失败", "error");
      this.state = 2;
    },
    // 刷新
    onRefresh() {
      this.state = "";
    },
  },
  mounted() {
    this.generateCode()
  },
}
</script>

<style lang="scss">
.body-bg {
  background-attachment: fixed;
  overflow: hidden;
}

.login-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #050608;
  display: flex;
  align-items: center;
  justify-content: center;
  .loginbox {
    width: 3.75rem;
    height: 4.3rem;
    padding: 0 0.625rem;
    padding-bottom: 0.75rem;
    box-shadow: 0 1 1 1;
    background-color: #fff;
    box-shadow: -0.025rem 0.0375rem 0.25rem #ccc;
     .title {
      font-size: 0.25rem;
      font-weight: bold;
      margin-left: 1.25rem;
      margin-top: 0.25rem;
    }
    .ip1 {
      width: 3.75rem;
      margin: 0.25rem 0;
    }
    .ip2 {
      width: 3.75rem;
    }
    .code{
      width: 3.75rem;
      height: 30px;
      font-size: 20px;
      display: flex;
      justify-content: flex-end;
      margin: .125rem 0;
      .coder{
         height: 30px;
         width: 100px;
         text-align: center;
         background: linear-gradient(to right, #FF4E50, #F9D423);
         cursor: pointer;
      }
    }
     .verification {
      margin-top: 0.25rem;
    }
    .btn {
      width: 3.75rem;
      margin: 0.25rem 0;
    }

    .zubox {
      width: 5rem;
      margin-top: 0.25rem;
      .zhuce {
        cursor: pointer;
        margin-left: 3.125rem;
      }
    }
    }
    .titles {
    display: flex;
    margin-top: 0.5rem;
    margin-left: 0.25rem;
    font-size: 0.25rem;
    font-weight: bold;
  }
    
}

</style>
