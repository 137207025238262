<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingTitle"
    class="app-container"
  >
    <div class="white-container clearfix">
      <span style="font-size: 14px">日期选择：</span>
      <el-date-picker
      clearable
        size="small"
        v-model="date"
        type="daterange"
        range-separator="至"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="getdate"
      >
      </el-date-picker>
      <el-button
        size="small"
        style="margin-left: 20px; margin-top: 20px"
        @click="search"
        icon="el-icon-search"
        type="primary"
      >
        搜索
      </el-button>
      <div class="neirong">
        <div class="left">
          <div style="display:flex;align-items: center; font-size: 18px;font-weight:bold; margin-bottom: 20px;color:#c7161f;margin-top:40px">
              <span style="width:104px;text-align: right;display: inline-block;">统计日期：</span> {{countData.date}}
               <span style="display: inline-block;width:2px;height:20px;background:#c7161f;margin-left:20px"></span> 
               <span style="margin-left:20px">总注册用户：</span>   {{countData.user_total}}
                  <span style="display: inline-block;width:2px;height:20px;background:#c7161f;margin-left:20px"></span> 
                   <span style="margin-left:20px">总点赞量：</span>   {{countData.total_like}}
                  <span style="display: inline-block;width:2px;height:20px;background:#c7161f;margin-left:20px"></span> 
                   <span style="margin-left:20px">总分享量：</span>   {{countData.total_share}}
                  <span style="display: inline-block;width:2px;height:20px;background:#c7161f;margin-left:20px"></span> 
                   <span style="margin-left:20px">总下载量：</span>   {{countData.total_download}}
               </div>
              
          <div style="font-size: 14px;font-weight:bold; margin-bottom: 10px;margin-top:60px">
           <span style="width:100px;text-align: right;display: inline-block;">注册用户：</span> {{countData.user}}
          </div>
          <div style="font-size: 14px;font-weight:bold; margin-bottom: 10px"><span style="width:100px;text-align: right;display: inline-block;">点赞量：</span> {{countData.like}}</div>
          <div style="font-size: 14px;font-weight:bold; margin-bottom: 10px"><span style="width:100px;text-align: right;display: inline-block;">分享量：</span> {{countData.share}}</div>
          <div style="font-size: 14px;font-weight:bold; margin-bottom: 10px"><span style="width:100px;text-align: right;display: inline-block;">下载量：</span> {{countData.download}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sucaiCount } from "@/Api/operation";
export default {
  data() {
    return {
      date: "",
      loadingTitle: "加载中...",
      loading: false,
       timer:'',
      countData: {
         date:'',
         user_total:'',
         user:'',
         like:'',
         share:'',
         download:'',
      },
    };
  },
  methods: {
      getdate(e){
          const joinedString = e.join(',');
           this.timer =  joinedString
      },
    getList() {
      sucaiCount({date:this.timer}).then((res) => {
        if(res.code == 1){
            this.countData =  res.data
        }
      });
    },
    search() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss">
.app-container {
  .white-container {
    height: 100vh;
    overflow: auto;
    .add {
      margin-top: 20px;
    }
    .neirong {
      min-width: 600px;
      min-height: 600px;
      margin-top: 20px;
      display: flex;
      .left {
        width: 100%;
      }
    }
  }
}
</style>
