// homeview 数据
export default {
    namespaced:true,
    state: {
        acticvpath:sessionStorage.getItem('path') || '/invitelink', // 菜单栏激活地址
        breadlsit:sessionStorage.getItem('breadlsits'), // 动态面包屑
        token:sessionStorage.getItem('token') || '',
        username:sessionStorage.getItem('username') || '', 
    },
    getters: {
    },
    mutations: {
        changestate(state,value){
            state.acticvpath = value;
            sessionStorage.setItem('path',value)
        },
        changebreadlsit(state,value){
            state.breadlsit = value;
      sessionStorage.setItem('breadlsits',value)
        },
        changetoken(state,value){
            state.token = value;
            sessionStorage.setItem('token',value)
        },
        changeusername(state,value){
            state.username = value,
            sessionStorage.setItem('username',value)
        }

    },
    actions: {
    },
    modules: {
    },
  }