<template>
  <div id="root">
    <!-- 头部 -->
    <div class="header">
      <div class="h-left">
        <img
          src="../../assets/imges/云律LOGO（横版） 3.png"
          alt=""
          width="
88.03"
          height="28"
        />
        <span
          style="
            width: 28px;
            height: 28px;
            opacity: 1;
            border-right: 1px solid #e3e3e3;
          "
        ></span>
        <h2 style="margin-left:20px">系统集成</h2>
        <el-breadcrumb class="bread" separator="/">
          <!-- <el-breadcrumb-item>
            <a href="/summary" >首页</a></el-breadcrumb-item
          > -->
          <template v-if="listbread.length > 0">
            <el-breadcrumb-item>{{ listbread[0] }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ listbread[1] }}</el-breadcrumb-item>
          </template>
        </el-breadcrumb>
      </div>
      <div class="h-right">
        <img src="../../assets//imges/头像.png" alt="">
        <span style="color: #000000; font-size: 12px">{{username}} </span>
        <div class="close" @click="close">
          <img src="../../assets/imges/icon退出登录.png" alt="" /><span
            style="color: #000000; font-size: 12px"
            >退出登录</span
          >
        </div>
      </div>
    </div>
    <!-- 大盒子 -->
    <div class="bigbox">
      <!-- 左侧导航栏 -->
      <el-aside width="220px">
        <el-menu
        unique-opened
          :default-active="acticvpaths"
          @open="handleOpen"
          @close="handleClose"
          background-color="#fff"
          text-color="#b6b6b6"
          active-text-color="#bc1d22"
          @select="select"
          router
        >
        <!-- 一级导航 -->
        
          <el-menu-item v-for="item in onelevel" :key="item.title" :index="item.name">
            <template slot="title">
              <i :class="item.ico"></i>
              <span slot="title">{{item.title}}</span>
            </template>
          </el-menu-item>
          <!-- 二级导航 -->
          <el-submenu v-for="item in twolevel" :key="item.title" :index="item.title">
            <template slot="title">
              <i :class="item.ico"></i>
              <span>{{item.title}}</span>
            </template>
            <el-menu-item-group v-for="item2 in item.chirdren" :key="item2.title">
              <el-menu-item :index="item2.name">{{item2.title}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容 -->
      <div class="r-conten">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui';
import message from '@/utils/messages'
export default {
  data() {
    return {
      routerlist:[],
      username:this.$store.state.home.username,
      openeds: ["2"],
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
 
      // 只有一级的菜单栏
      onelevel:[],
      // 两级菜单栏
      twolevel:[],
    };
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    //菜单栏选中
    select(a, b) {
      console.log(a,b);
      this.$store.commit("home/changestate", a);
      localStorage.setItem("bread", b[0]);
    },
    close() {
      MessageBox.confirm("此操将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
         message( "退出登录成功",'success');
          sessionStorage.clear();
        
          this.$router.push("/login");
             window.location.reload();
         
        })
        .catch(() => {
           message( "已取消退出登录",'info');
        });
    },
  },
  computed: {
    acticvpaths() {
      return this.$store.state.home.acticvpath;
    },
    listbread() {
      return this.$store.state.home.breadlsit;
    },
  },
  mounted() {
    this.routerlist = JSON.parse(sessionStorage.getItem('routerlist')) 
    let newarr =   this.routerlist.filter(item=>{
      return item.pid == 0
    }) 
    newarr.forEach(item=>{
      item.chirdren = []
    })
    newarr.forEach(item=>{
      for (let index = 0; index < this.routerlist.length; index++) {
      if(this.routerlist[index].pid == item.id) {
      item.chirdren.push(this.routerlist[index])
      }
    }
    })
    newarr.forEach(item => {
        if(item.chirdren.length < 1){
          this.onelevel.push(item)
        }
        if(item.chirdren.length >0){
           this.twolevel.push(item)
        }
    });
  },
};
</script>
<style lang="scss" scoped>
#root {

  // 头部
  .header {
    height: 56px;
    width: 24rem;
    border-bottom: 1px solid #f7f8fa;
    padding: 10px;
    overflow: hidden;
    height: auto;
    display: flex;
    justify-content: space-between;
    .houtai {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height:22px;
      margin-left: 12px;
    }
    .h-left {
      width: auto;
      height: 40px;
      display: flex;
      align-items: center;
      .bread {
        margin-left: 20px;
      }
    }
    .h-right {
      display: flex;
      align-items: center;
      width: 260px;
      height: 40px;
      .close {
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
    }
  }
  .bigbox {
    width: 24rem;
    height: auto;
    display: flex;

    .r-conten {
      flex: 1;
      background-color: #f2f3f5;
      padding: 10px;
      overflow-y: scroll;
    }
  }
}
/* 文字 */

::v-deep.el-submenu.is-active > .el-submenu__title {
  background: #c7161f !important;
 color: #ffffff !important;
}
/* icon图标也跟着变 */
::v-deep .el-submenu.is-active > .el-submenu__title i {
  background: #c7161f !important;
 color: #ffffff !important;
}
::v-deep.el-menu {
  border-right: 0;
}
</style>
