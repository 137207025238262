<template>
  <div class="content">
    <el-input
      style="width: 400px; margin-left: 24px; margin-top: 24px"
      placeholder="请输入搜索内容"
      v-model="input1"
    >
      <i slot="suffix" class="el-icon-search" @click="search"></i>
    </el-input>
    <el-button @click="addrole" class="search" icon="el-icon-plus" size="mini"
      >新增</el-button
    >
    <!-- 表格 -->
    <div class="tablebox">
      <el-table
        :data="tableData"
        size="mini"
        style="width: 93.5%; margin-top: 54px; margin-left: 20px"
      >
        <el-table-column label="角色" width="auto">
          <template slot-scope="scope">
            <div @click="handleEdit(scope.$index, scope.row)">
              <span>{{ scope.row.title }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="描述" width="auto">
          <template slot-scope="scope">
            <div @click="handleEdit(scope.$index, scope.row)">
              <span>{{ scope.row.commit }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              style="color: #2a84e9; cursor: pointer"
              class="hover"
              @click="redact(scope.$index, scope.row)"
            >
              编辑
            </span>
            <span
              style="color: #bc1d22; cursor: pointer"
              class="hover"
              @click="handlete(scope.$index, scope.row)"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页dom -->
    <div class="block">
      <el-pagination
        :pager-count="pagercount"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pagesize"
        layout="total, prev, pager, next,sizes"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { rolelist,roledelet } from "@/Api/roal/index.js";
import message from '@/utils/messages';
export default {
  data() {
    return {
      pagercount: 5,
      input1: "", // 搜索框内容
      tableData: [
        {
          type_name: "谢霆锋",
          order_name: 245786,
          color: "159****5340",
          order_status: "律友",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "王艺霖",
          order_name: 245786,
          color: "159****5340",
          order_status: "普通",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "张学伟",
          order_name: 245786,
          color: "159****5340",
          order_status: "律友",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "林俊杰",
          order_name: 245786,
          color: "159****5340",
          order_status: "普通",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "周杰伦",
          order_name: 245786,
          color: "159****5340",
          order_status: "律友",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "早安",
          order_name: 245786,
          color: "159****5340",
          order_status: "律友",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "狗哥",
          order_name: 245786,
          color: "159****5340",
          order_status: "律友",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "潘玮柏",
          order_name: 245786,
          color: "159****5340",
          order_status: "普通",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "刘德华",
          order_name: 245786,
          color: "159****5340",
          order_status: "律友",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
        {
          type_name: "荞麦",
          order_name: 245786,
          color: "159****5340",
          order_status: "普通",
          order_num: "94w",
          send_name: "2023-05-30  17:47:36",
          custom: "兑换码",
          list: "查看邀请列表",
        },
      ], // 表格数据
      currentPage: 1, // 当前页数
      pagesize: 10, // 页数大小
      total: 10 // 数据总条数
    };
  },
  methods: {
    // 编辑
    redact(index,row) {
      console.log(index,row);

      this.$router.push({ path: '/roleedit', query: { rules: row.rules ,id:row.id,title:row.title,commit:row.commit}});
    },
    // 重置密码
    resetpwd() {},
    // 删除
   async handlete(index,row) {
      console.log(index);
       await roledelet({
         token:sessionStorage.getItem('token'),
         id:row.id
       }).then(res=>{
         if(res.code == 1){
           message('删除成功','success')
           this.gettinglist()
         }
       })
      
    },
    // 添加人员
    addrole() {
      this.$router.push("/roleedit");
    },
    handleSizeChange(e) {
      this.pagesize = e;
      this.gettinglist();
    },
    handleCurrentChange(e) {
      this.currentPage = e;
       this.gettinglist();
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.$router.push("/carmidetail");
    },
    search() {
      console.log(123);
    },
    // 获取列表
    async gettinglist() {
      await rolelist({
        page: 1,
        pageSize: 10,
        token: sessionStorage.getItem("token"),
      }).then((res) => {
        if(res.code == 1){
 this.tableData = res.data.list
        }
         
      });
    },
  },
   mounted() {
     this.gettinglist()
   },
};
</script>

<style lang="scss" scoped>
.content {
  width: 21rem;
  height: 12.4rem;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .search {
    width: auto;
    height: 0.4rem;
    margin-left: 0.8rem;
    margin-right: 0.2rem;
    background-color: #bc1d22;
    color: #fff;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
  }
}
::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 32px;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
::v-deep .el-pagination {
  margin-left: 14rem;
  padding-top: 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #bc1d22;
  color: #fff;
}
::v-deep .el-input__suffix {
  padding-top: 6px;
  right: 5px;
  transition: all 0.3s;
  pointer-events: none;
}
</style>
