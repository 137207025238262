<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingTitle"
    class="app-container"
  >
    <div class="white-container clearfix">
  <el-form
        style="margin-top: 20px"
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-form-item label-width="100px" label="轮播图增改:" required>
          <div class="flex-c-s" style="align-items: start">
            <div v-for="(item, index) in swiperList" :key="index" class="mr15">
              <el-upload
                class="avatar-uploader"
                :action="upload_url"
                :show-file-list="false"
                :on-success="
                  (response, file, fileList) =>
                    handleAvatarSuccess4(response, file, fileList, index)
                "
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="item" :src="item" class="avatar" />
                <img
                  v-if="item"
                  src="@/button/img_del.png"
                  class="img-del"
                  @click.stop="delImg4(index)"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </div>
          <div class="tip-yellow">
            温馨提示：只能上传jpg、png、jpeg文件，最少1张最多6张
          </div>
        </el-form-item>
      <el-form-item>
        <el-transfer style="margin-left: 20px" target-order="push" @change="changSort" v-model="value" :data="data" :titles="['排序前','排序后']"></el-transfer>
      </el-form-item>
        <el-form-item>
          <el-button style="margin-left:20px;margin-top: 20px" type="primary" @click="changeSwiper">立即操作</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import { addbaner,editbaner,getbaner} from "@/Api/operation";
import { ceshi } from "@/utils/requset";
import { Message } from "element-ui";
export default {
  data() {
    return {
        changebanID: "",
        form:{},
         addBanners: true,
         swiperList: ["", "", "", "", "", ""], // 轮播图片
      loadingTitle: "加载中...",
      loading: false,
     upload_url: ceshi + "/common/uploadFile/upload",
     data:[], // 排序前
     value:[] // 排序后
    };
  },
  methods: {
          // 修改内容删除轮播图
    delImg4(index) {
      console.log(index);
      const arrTemp = JSON.parse(JSON.stringify(this.swiperList));
      arrTemp[index] = "";
      this.swiperList = arrTemp;
    },
          // 上传轮播
    handleAvatarSuccess4(res, file, fileList, index) {
      this.loading = false;
      this.loadingTitle = "加载中...";
      // this.form.banner_chart[index] = res.data.fullurl
      if (res.code != 1) {
        Message({
          message: res.msg,
          type: "warning",
        });
        return;
      }
      const arrTemp = JSON.parse(JSON.stringify(this.swiperList));
      arrTemp[index] = res.data.fullurl;
      this.swiperList = arrTemp;
      console.log(this.swiperList);
    },
          changSort(e){
            this.value = e
      },
        // 检查是否存在banner列表
    checkBanner() {
      getbaner({}).then((res) => {
        if (res.data.url.length > 0) {
          this.changebanID = res.data.id;
          this.swiperList = res.data.url;
          let arr = [] 
          for (let index = 0; index < res.data.url.length; index++) {
           arr.push({key:index,label:`第${index+1}张`})
          }
          this.data = arr
          this.addBanners = false;
        } else {
          this.addBanners = true;
        }
      });
    },
        beforeAvatarUpload(file) {
      this.loading = true;
      this.loadingTitle = "上传中...";
      console.log(file);
      // const isLt5M = file.size / 1024 / 1024 < 2;
      // if (!isLt5M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      // return isLt5M;
    },
        changeSwiper() {

      let nullNum = 0;
      this.swiperList.forEach((item) => {
        if (item == "") {
          nullNum++;
        }
      });
      if (nullNum == 6) {
        console.log("轮播图片不能为空");
        Message({
          message: "轮播图片不能为空",
          type: "error",
        });
        return;
      }
      let arr = []
      if(this.value.length > 0){
      this.value.forEach(item=>{
        arr.push(this.swiperList[item])
      })
      }
   
      // 添加操作
      if (this.addBanners) {
        if(arr.length > 0 && arr.length < 6){
            Message({
              message: "6张都需要进行排序",
              type: "error",
            });
            return
        }
        addbaner({
          list: this.value.length > 0?arr:this.swiperList,
        }).then((res) => {
          if (res.code == 1) {
            Message({
              message: "操作成功",
              type: "success",
            });
            this.value = []
            this.addBanners = false;
             this.checkBanner()
            return;
          }
        });
      }
      if(arr.length > 0 && arr.length < 6){
            Message({
              message: "6张都需要进行排序",
              type: "error",
            });
            return
        }
      // 修改操作
      editbaner({
        id: this.changebanID,
        list: this.value.length > 0?arr:this.swiperList,
      }).then((res) => {
        if (res.code == 1) {
          Message({
            message: "操作成功",
            type: "success",
          });
          this.value = []
           this.checkBanner()
        }
      });
 
    },
  
  },
  created() {
    this.checkBanner()
  },
};
</script>
<style lang="scss" >
.app-container {
  .white-container {
    height: 100vh;
    overflow: auto;
    .add {
      margin-top: 20px;
    }
    .neirong {
      min-width: 600px;
      min-height: 600px;
      margin-top: 20px;
      display: flex;
      .left {
        width: 100%;
      }
    }
  }
}
</style>
