<template>
  <div class="content">
    <div class="titlebox">
      <div class="title"></div>
      <span class="title2">人员新增</span>
    </div>
    <!-- 姓名 -->
    <div class="username">
      <span>用户名</span>
      <el-input
        class="name"
        placeholder="请输入用户名"
        v-model="username"
        clearable
      >
      </el-input>
    </div>
    <!-- 昵称 -->
        <div class="userpwd">
      <span>昵称</span>
      <el-input
        class="upd"
        placeholder="请输入昵称"
        v-model="nickname"
        clearable
      >
      </el-input>
    </div>
    <!-- 手机号 -->
    <div class="phone">
      <span>手机号</span>
      <el-input
        class="phao"
        placeholder="请输入手机号"
        v-model="phone"
        clearable
      >
      </el-input>
    </div>
    <!-- 密码 -->
    <div class="userpwd">
      <span>密码</span>
      <el-input
        class="upd"
        placeholder="请输入密码"
        v-model="userpwd"
        clearable
        show-password
      >
      </el-input>
    </div>
    <!-- 是否为督导 -->
    <div class="dudao">
      <span>是否为督导</span>
      <el-radio class="fou" v-model="radio" label="2">否</el-radio>
      <el-radio v-model="radio" label="1">是</el-radio>
    </div>
    <!-- 是否批量购买 -->
    <!-- <div class="dudao2">
      <span>是否批量购买</span>
      <el-radio class="fou" v-model="radio2" label="1">否</el-radio>
      <el-radio v-model="radio2" label="0">是</el-radio>
    </div> -->
    <!-- 权限设置 -->
    <div class="power">
      <span style="margin-right:14px">权限设置</span>
     <el-select v-model="value1"  placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.title"
      :value="item.id">
    </el-option>
  </el-select>
    </div>
         <el-row style="margin-top: 10px">
                  <span style="margin-left:78px;margin-right:14px;" >公司归属</span>
              <el-select
          size="small"
          v-model="source_type"
          clearable
          placeholder="选择公司归属"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
          </el-row>
    <!-- 按钮 -->
    <div class="btnbox">
      <el-button
        type="info"
        style="background: #f2f3f5; border: 0; color: #666666"
        size="mini"
        @click="reback"
        >返回</el-button
      >
      <el-button
        type="info"
        size="mini"
        style="background-color: #bc1d22; border: 0"
        @click="save"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import {sourcelist} from "@/Api/operation";
import {reject,getselect} from "../../../Api/login/index.js";
import message from "@/utils/messages.js";
export default {
  data() {
    return {
      source_type:'',// 公司归属
      sourceList:[],// 公司归属
      nickname:'',// 昵称
      username: "", // 姓名
      phone: "", // 手机号
      userpwd: "", // 密码
      surepwd: "", // 确认密码
      radio: "1", // 是否为督导
      radio2:'0', // 是否可以批量购买
      remark: "", // 备注
      checkList: ["超级管理员", "管理员"], // 多选框
       options: [], // 下拉框配置
        value1: [],
    };
  },
  methods: {
    // 返回
    reback() {
      this.$router.push("/personmangent");
    },
          // 获取公司归属列表
    getSourceList(){
      sourcelist({}).then(res=>{
        if(res.code == 1){
          this.sourceList = res.data
        }
      })
    },
    // 保存
   async save() {
     console.log(this.value1);
      var fruit = "";
      if (fruit === this.username) {
        message("用户名不能为空", "error");
        return;
      } else if (fruit === this.phone) {
        message("手机号不能为空", "error");
        return;
      } else if (fruit === this.userpwd) {
        message("密码不能为空", "error");
        return;
      }
      else if (fruit === this.source_type) {
        message("公司归属不能为空", "error");
        return;
      } else {
       if(this.validatePhoneNumber(this.phone) == false){
         message("手机号不存在,请输入正确的手机号", "error")
         return;
       }
        await reject({
          token:this.$store.state.home.token,
          username:this.username,
          nickname:this.nickname,
          password:this.userpwd,
          phone:this.phone,
          type:this.radio,
          group_id:this.value1,
          buy_status:this.radio2,
          company_id:this.source_type
        }).then((res)=>{
          if(res.code == 1){
message('保存成功','success')
this.$router.push('/personmangent')
          }
        }).catch(()=>{
              message('保存失败','error')
        })
      }
    },
    // 正则验证手机号
    validatePhoneNumber(phoneNumber) {
      // 使用正则表达式验证手机号格式
      const regex = /^1[3456789]\d{9}$/;
      return regex.test(phoneNumber);
    },
  },
 async mounted(){
   await getselect({
     token:sessionStorage.getItem('token')
   }).then(res=>{
    if(res.code == 1){
      this.options = res.data
    }
   })
   await this.getSourceList()
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 20.7rem;
  height: 12.4rem;

  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .titlebox {
    display: flex;
    align-items: center;
    margin-left: 0.3rem;
    padding-top: 0.3rem;
    .title {
      width: 3px;
      height: 18px;
      background: #bc1d22;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-right: 8px;
    }
    .title2 {
      width: 80px;
      height: 24px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
  }
  .username {
    margin-top: 55px;
    display: flex;
    align-items: center;
    margin-left: 90px;
    .name {
      width: 4.6875rem;
      margin-left: 16px;
    }
  }
  .phone {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-left: 90px;
    .phao {
      width: 4.6875rem;
      margin-left: 16px;
    }
  }
  .userpwd {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-left: 102px;
    .upd {
      width: 4.6875rem;
      margin-left: 16px;
    }
  }
  .surepwd {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-left: 78px;
    .spwd {
      width: 4.6875rem;
      margin-left: 16px;
    }
  }
  .dudao {
    margin-top: 20px;
    margin-left: 66px;
    display: flex;
    align-items: center;
    .fou {
      margin-left: 16px;
    }
  }
  .dudao2{
     margin-top: 20px;
    margin-left: 54px;
    display: flex;
    align-items: center;
    .fou {
      margin-left: 16px;
    }
  }
  .remark {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-left: 102px;
    .beizhu {
      width: 4.6875rem;
      margin-left: 16px;
    }
  }
  .power {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-left: 78px;
    .checkList {
      margin-left: 16px;
    }
  }
  .btnbox {
    margin-left: 142px;
    margin-top: 20px;
  }
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #bc1d22;
  background: #bc1d22;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 30px;
  background-color: #f2f3f5;
  border: 0;
}
::v-deep .el-input__icon {
  line-height: 32px;
}
::v-deep .el-textarea__inner {
  line-height: 44px;
  height: 44px;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: black;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #bc1d22;
  border-color: #bc1d22;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
</style>
