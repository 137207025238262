import { render, staticRenderFns } from "./StarBackground.vue?vue&type=template&id=383bad4e&scoped=true"
import script from "./StarBackground.vue?vue&type=script&lang=js"
export * from "./StarBackground.vue?vue&type=script&lang=js"
import style0 from "./StarBackground.vue?vue&type=style&index=0&id=383bad4e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "383bad4e",
  null
  
)

export default component.exports